export interface AppsResponse {
  data: App[];
  message: string;
}

export interface AppResponse {
  data: App;
  message: string;
}

export interface App {
  id: number;

  email?: string;

  appName: string;
  appStatus: APP_STATUS;

  description?: string;
  currentDevelopmentStatus?: CURRENT_DEVELOPMENT_STATUS;

  tokenTicker: string;
  totalRaiseAmount?: string;
  telegramId?: string;

  appAdditionalInfo?: AppAdditionalInfo;
  appPools: AppPool[];
}

export interface AppPool {
  id: number;
  appId: number;

  poolAccountId: string;
}

export interface AppAdditionalInfo {
  id: number;
  appId: number;

  tokenUtilityDescription?: string;

  appLogoUrl?: string;
  appBackgroundUrl?: string;

  whitepaperUrl?: string;
  webpageUrl?: string;

  roadmapUrl?: string;
  tokenomicsUrl?: string;
  partnersUrl?: string;

  twitterUrl?: string;
  telegramUrl?: string;
  mediumUrl?: string;
}

export enum APP_STATUS {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  CLOSED = 'CLOSED',
}

export enum CURRENT_DEVELOPMENT_STATUS {
  IDEATION = 'IDEATION',
  CREATED_WHITEPAPER = 'CREATED_WHITEPAPER',
  EARLY_DEVELOPMENT = 'EARLY_DEVELOPMENT',
  MID_DEVELOPMENT = 'MID_DEVELOPMENT',
  LATE_DEVELOPMENT = 'LATE_DEVELOPMENT',
  ALL_PREPARED_FOR_LAUNCH = 'ALL_PREPARED_FOR_LAUNCH',
  ALREADY_LAUNCHED = 'ALREADY_LAUNCHED',
}

export enum ROLE {
  ADMIN = 'ADMIN',
  USER = 'USER',
}
