import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { API_URL } from '~/constants/urls';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});

// change case to camel before response
api.interceptors.request.use(request => {
  if (request.params) request.params = snakecaseKeys(request.data, { deep: true });
  if (request.data) request.data = snakecaseKeys(request.data, { deep: true });

  return request;
});

// change case to camel before response
api.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'].includes('application/json'))
      response.data = camelcaseKeys(response.data, { deep: true });

    return response;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);
