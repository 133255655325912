import { App, APP_STATUS, CURRENT_DEVELOPMENT_STATUS } from '~/types/app';

export const mockApp: App = {
  id: 1,
  email: '/',

  appName: 'Paras',
  appStatus: APP_STATUS.UPCOMING,

  description:
    'NFT Marketplace for digital collectibles. Create, Trade, and Collect Digital Collectibles. All-in-one social NFT marketplace for creators and collectors. NFT Marketplace for digital collectibles. Create, Trade, and Collect Digital Collectibles. All-in-one social NFT marketplace for creators and collectors.',
  currentDevelopmentStatus: CURRENT_DEVELOPMENT_STATUS.EARLY_DEVELOPMENT,

  tokenTicker: '$PARAS',
  totalRaiseAmount: '1000000000',

  telegramId: '/',

  appAdditionalInfo: {
    id: 1,
    appId: 1,

    tokenUtilityDescription: '',

    appBackgroundUrl: 'https://d37i60kx19rg81.cloudfront.net/images/image-sample-bg-2.png',
    appLogoUrl: 'https://d37i60kx19rg81.cloudfront.net/images/image-sample-logo-2.png',

    whitepaperUrl: '',
    webpageUrl: '',

    roadmapUrl: 'https://d37i60kx19rg81.cloudfront.net/images/image-sample-empty.png',
    tokenomicsUrl: 'https://d37i60kx19rg81.cloudfront.net/images/image-sample-empty.png',
    partnersUrl: 'https://d37i60kx19rg81.cloudfront.net/images/image-sample-empty.png',

    twitterUrl: '/',
    telegramUrl: '/',
    mediumUrl: '/',
  },

  appPools: [
    { id: 1, appId: 1, poolAccountId: '' },
    { id: 2, appId: 1, poolAccountId: '' },
  ],
};
