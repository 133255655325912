import { connect, keyStores } from 'near-api-js';

import SpecialWallet from '~/configs/init-near-wallet';

export const keyStore = new keyStores.BrowserLocalStorageKeyStore();

export const nearGateContract: Record<Net, string> = {
  mainnet: 'neargate-v0.near',
  testnet: 'neargate-v0.testnet',
};
export const usnContract: Record<Net, string> = {
  mainnet: 'usn',
  testnet: 'usdn.testnet',
};

export interface Config {
  networkId: Net;
  keyStore: keyStores.BrowserLocalStorageKeyStore;
  nodeUrl: string;
  walletUrl: string;
  explorerUrl: string;
}

const getNetFromEnv = (env: string): Net => {
  switch (env) {
    case 'prod':
      return 'mainnet';
    case 'local':
    case 'dev':
    case 'testnet':
      return 'testnet';
    default:
      return 'mainnet';
  }
};

const getNearNetwork = (net: Net): Config => ({
  networkId: `${net}`,
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
  nodeUrl: `https://rpc.${net}.near.org`,
  walletUrl: `https://wallet.${net}.near.org`,
  explorerUrl: `https://explorer.${net}.near.org`,
});

export const initNearProtocol = async () => {
  try {
    const net = getNetFromEnv(process.env.REACT_APP_START_ENV || 'testnet');
    const config = getNearNetwork(net);

    const near = await connect({
      headers: {},
      ...config,
    });

    const userWallet = new SpecialWallet(near, 'NEARGATE');
    userWallet.account();

    const senderAccountId = net === 'mainnet' ? 'neargate-sender.near' : 'neargate-sender.testnet';
    const senderWallet = await near.account(senderAccountId);

    window.near = {
      config,
      wallet: userWallet,
      senderWallet,
    };
  } catch (e) {
    throw Error('Error in initializing near protocol');
  }
};
