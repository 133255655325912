import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';

import { ScrollTop } from '~/components/scroll-top';

import { RouteWrapper } from './routes.styled';

import 'react-toastify/dist/ReactToastify.css';

const ErrorPage = loadable(() => import('~/pages/error'));
const HomePage = loadable(() => import('~/pages/home'));
const AppPage = loadable(() => import('~/pages/app'));
const AppDetailPage = loadable(() => import('~/pages/app-detail'));

const EntryRoute = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <RouteWrapper>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/app" element={<AppPage />} />;
          <Route path="/app/:id" element={<AppDetailPage />} />;
          <Route path="/error" element={<ErrorPage />} />;
          <Route path="*" element={<HomePage />} />
        </Routes>
      </RouteWrapper>
      <ToastContainer theme={'dark'} />
    </BrowserRouter>
  );
};

export default EntryRoute;
