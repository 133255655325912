import { IS_DEV, IS_LOCAL, IS_PROD } from '~/constants/env';

export const NEARGATE_ASSETS = 'https://d37i60kx19rg81.cloudfront.net/';

export const API_URL_BASE = IS_LOCAL
  ? 'http://localhost:8080'
  : IS_DEV
  ? 'https://api-dev.neargate.net'
  : IS_PROD
  ? 'https://api.neargate.net'
  : 'https://api.neargate.net';
export const API_URL = `${API_URL_BASE}`;

export const IDO_FORM_LINK = 'https://forms.gle/Us8jcH4C9jDjdquz5';

export const NEARGATE_TWITTER = 'https://twitter.com/NearGate_HQ';
export const NEARGATE_TELEGRAM_ANNOUNCEMENT = 'https://t.me/neargate_official';
export const NEARGATE_MEDIUM = 'https://medium.com/@neargate';
export const NEARGATE_MAIL = 'mailto:support@neargate.net';
