import { rest } from 'msw';

import { APP_STATUS, AppResponse, AppsResponse } from '~/types/app';

import { api } from '~/api/axios';
import { API_URL } from '~/constants/urls';

import { mockApp } from '../data/app';

export const getApps = async (query?: APP_STATUS) => {
  const getQueryString = () => {
    if (!query) return '';

    if (query === APP_STATUS.ACTIVE) return `?status=${APP_STATUS.ACTIVE}`;
    if (query === APP_STATUS.UPCOMING) return `?status=${APP_STATUS.UPCOMING}`;
    if (query === APP_STATUS.CLOSED) return `?status=${APP_STATUS.CLOSED}`;
  };

  const response = await api.get<AppsResponse>(`/apps${getQueryString()}`);
  return response.data.data;
};

export const getApp = async (appId: number) => {
  const response = await api.get<AppResponse>(`/apps/${appId}`);
  return response.data.data;
};

export const mockAppAPI = [
  rest.get(`${API_URL}/apps`, (req, res, ctx) => {
    const appStatus: string | null = req.url.searchParams.get('status');

    if (appStatus === APP_STATUS.CLOSED || appStatus === APP_STATUS.ACTIVE)
      return res(ctx.status(200), ctx.json({ data: [] }));

    return res(ctx.status(200), ctx.json({ data: [mockApp] }));
  }),
  rest.get(`${API_URL}/apps/:id`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: mockApp }))
  ),
];
