import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

import { mockServiceWorker } from '~/__mocks__/browser';
import reportWebVitals from '~/configs/web-vitals';
import EntryRoute from '~/routes';

import { initNearProtocol } from './configs/init-near-protocol';
import { IS_MOCK } from './constants/env';

import '~/styles/build/index.css';

if (IS_MOCK) {
  mockServiceWorker.start();
}

const client = new QueryClient({
  defaultOptions: {
    queries: { useErrorBoundary: true, suspense: true },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
initNearProtocol().then(() => {
  root.render(
    <RecoilRoot>
      <QueryClientProvider client={client}>
        <EntryRoute />
      </QueryClientProvider>
    </RecoilRoot>
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
